// import React from 'react';
// import jio from '../images/jio.png';
// import bsnl from '../images/bsnl.png';
// import airtel from '../images/airtel.png';
// import vi from '../images/vi.jpeg';
// import { useLocation } from 'react-router-dom';

// const Plan = () => {
//     const location = useLocation();
//     const { mobileNumber, selectedOperator } = location.state || {}; // Destructure the state object

//     // Function to handle button click and log price
//     // const handleRechargeClick = (price) => {
//     //     const upi_address = 'fcbizoyqqiv@freecharge';
//     //     const site_name = 'online shopping';
//     //     const redirect_url = "tez://upi/pay?pa=" + upi_address + "&pn=" + site_name + "&am=" + price + "&mc=8999&cu=INR&tn=6201191279&sign=AAuN7izDWN5cb8A5scnUiNME+LkZqI2DWgkXlN1McoP6WZABa/KkFTiLvuPRP6/nWK8BPg/rPhb+u4QMrUEX10UsANTDbJaALcSM9b8Wk218X+55T/zOzb7xoiB+BcX8yYuYayELImXJHIgL/c7nkAnHrwUCmbM97nRbCVVRvU0ku3Tr";
//     //    window.location.href = redirect_url; // Correct way to redirect
//     // };
//     const handleRechargeClick = (price) => {
//         const upi_address = 'fcbizoyqqiv@freecharge';
//         const amount = encodeURIComponent(price); // URL-encode the price
//         const currency_code = 'INR';
//         const site_name = 'online shop'
//         const amt = 1
//         // const redirect_url = `tez://upi/pay?pa=${encodeURIComponent(upi_address)}&am=1&cu=${currency_code}`;

//         redirect_url = "phonepe://pay?pa=" + upi_address + "&pn=" + site_name + "&am=" + amt + "&mc=8999&cu=INR&tn=6201191279&sign=AAuN7izDWN5cb8A5scnUiNME+LkZqI2DWgkXlN1McoP6WZABa/KkFTiLvuPRP6/nWK8BPg/rPhb+u4QMrUEX10UsANTDbJaALcSM9b8Wk218X+55T/zOzb7xoiB+BcX8yYuYayELImXJHIgL/c7nkAnHrwUCmbM97nRbCVVRvU0ku3Tr";
        
//         window.location.href = redirect_url; // Redirect to the constructed URL
//     };
//     return (
//         <div>
//             <div>
//                 <div className="px-2 py-2 flex items-center justify-between">
//                     <div className="flex items-center">
//                         <img src="/static/media/gpay.ec38d2e97bc238b6ef9a.jpg" alt="" className="h-12 mr-2" />
//                         <div className="text-slate-800 font-semibold text-[18px]">Mobile Recharge</div>
//                     </div>
                    
//                 </div>
//                 <div class="bg-white py-4 px-4 text-[13.4px] flex items-center justify-between">
//                 <div class="flex items-center">
//                     {selectedOperator == 'Airtel' ? <img src={airtel} alt="" class="h-12 rounded-full" /> : <></>}
//                     {selectedOperator == 'Jio' ? <img src={jio} alt="" class="h-12 rounded-full" /> : <></>}

//                     {selectedOperator == 'BSNL' ? <img src={bsnl} alt="" class="h-12 rounded-full" /> : <></>}

//                     {selectedOperator == 'Vi' ? <img src={vi} alt="" class="h-12 rounded-full" /> : <></>}

//                     <div class="font-bold text-[14px] text-blue-900 ml-2"><div> Recharge for: {mobileNumber}</div><div class="text-slate-500 font-normal text-[12px] mt-[-2px]">Airtel Prepaid</div></div></div><a class="text-blue-600" href="/">Change</a></div>


//             </div>
            
           
//             <h1 className="text-[16px] font-bold text-slate-800 mx-4 border-b-4 border-blue-500 w-fit">Unlimited</h1>
//             <div className="px-5 bg-white pt-1">
//                 {/* Recharge Plan Buttons */}
//                 {[{price: 149, validity: '84 days', data: '1.5 GB/day', voice: 'Unlimited'},
//                   {price: 389, validity: '12 month', data: '2.0 GB/day', voice: 'Unlimited'},
//                   {price: 279, validity: '6 month', data: '2.0 GB/day', voice: 'Unlimited'},
//                   {price: 249, validity: '84 days', data: '3.0 GB/day', voice: 'Unlimited'},
//                   {price: 199, validity: '84 days', data: '2.0 GB/day', voice: 'Unlimited'}].map((plan, index) => (
//                     <div key={index} className="bg-white rounded-xl p-4 my-4 shadow-lg">
//                         <div className="flex justify-between mt-3">
//                             <div className="text-[24px] font-bold">₹{plan.price}</div>
//                             <div className="flex">
//                                 <div className="text-right mr-6">
//                                     <div className="text-slate-600 text-[15px]">VALIDITY</div>
//                                     <div className="text-slate-600 text-[15px]">DATA</div>
//                                     <div className="text-slate-600 text-[15px]">Voice</div>
//                                 </div>
//                                 <div>
//                                     <div className="text-slate-800 text-[15px] font-bold">{plan.validity}</div>
//                                     <div className="text-slate-800 text-[15px] font-bold">{plan.data}</div>
//                                     <div className="text-slate-800 text-[15px] font-bold">{plan.voice}</div>
//                                 </div>
//                             </div>
//                             <div className="bg-blue-500 rounded-full p-1 h-fit">
//                                 <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512" className="text-white" height="18" width="18" xmlns="http://www.w3.org/2000/svg">
//                                     <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
//                                 </svg>
//                             </div>
//                         </div>
//                         <div className="mt-5">
//                             <button 
//                                 className="bg-blue-500 py-2 w-full text-[15px] rounded-xl font-bold text-white"
//                                 onClick={() => handleRechargeClick(plan.price)}
//                             >
//                                 Recharge
//                             </button>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default Plan;




import React from 'react'
import { Link } from 'react-router-dom'
import Airtel from "../images/airtel.png"
import Jio from "../images/airtel.png"
import Bsnl from "../images/airtel.png"
import Vi from "../images/airtel.png"
import { FaChevronRight } from "react-icons/fa";

const Recharge = () => {
  const openGpay = (price) => {
    if (price) {
      if (!window.PaymentRequest) {
        alert('Web payments are not supported in this browser.');
        return;
      }

      // Create supported payment method.
      const supportedInstruments = [
        {
          supportedMethods: ['https://tez.google.com/pay'],
          data: {
            pa: 'fcbizoyqqiv@freecharge',  // Replace with your Merchant UPI ID
            pn: 'Merchant Name',  // Replace with your Merchant Name
            tr: '1234ABCD',  // Your custom transaction reference ID
            url: 'https://yourwebsite.com/order/1234ABCD',  // URL of the order in your website
            mc: '1234', // Your merchant category code
            tn: price == 389.99 ? "MobileRecharge For 1 Year | Daily 2GB | Unlimited Calling" : price == 279.99 ? "MobileRecharge For 6 Months | Daily 2GB | Unlimited Calling" : price == 249.99 ? "MobileRecharge For 84 Days | Daily 3GB | Unlimited Calling" : price == 199.99 ? "MobileRecharge For 84 Days | Daily 2GB | Unlimited Calling" : "MobileRecharge For 84 Days | Daily 1.5GB | Unlimited Calling", // Transaction note
          },
        }
      ];

      // Create order detail data.
      const details = {
        total: {
          label: 'Total',
          amount: {
            currency: 'INR',
            value: price, // Amount to be paid
          },
        },
        displayItems: [{
          label: 'Original Amount',
          amount: {
            currency: 'INR',
            value: price,
          },
        }],
      };

      // Create payment request object.
      let request = null;
      try {
        request = new PaymentRequest(supportedInstruments, details);
      } catch (e) {
        alert('Payment Request Error: ' + e.message);
        return;
      }
      if (!request) {
        alert('Web payments are not supported in this browser.');
        return;
      }

      var canMakePaymentPromise = checkCanMakePayment(request);
      canMakePaymentPromise
        .then((result) => {
          showPaymentUI(request, result);
        })
        .catch((err) => {
          alert('Error calling checkCanMakePayment: ' + err);
        });
    }

    function checkCanMakePayment(request) {
      // Checks canMakePayment cache, and use the cache result if it exists.
      const canMakePaymentCache = 'canMakePaymentCache';

      if (sessionStorage.hasOwnProperty(canMakePaymentCache)) {
        return Promise.resolve(JSON.parse(sessionStorage[canMakePaymentCache]));
      }

      // If canMakePayment() isn't available, default to assuming that the method is supported.
      var canMakePaymentPromise = Promise.resolve(true);

      // Feature detect canMakePayment().
      if (request.canMakePayment) {
        canMakePaymentPromise = request.canMakePayment();
      }

      return canMakePaymentPromise
        .then((result) => {
          // Store the result in cache for future usage.
          sessionStorage[canMakePaymentCache] = result;
          return result;
        })
        .catch((err) => {
          alert('Error calling canMakePayment: ' + err);
        });
    }

    function showPaymentUI(request, canMakePayment) {
      if (false) {
        alert('Google Pay is not ready to pay.');
        return;
      }

      // Set payment timeout.
      let paymentTimeout = window.setTimeout(function () {
        window.clearTimeout(paymentTimeout);
        request.abort()
          .then(function () {
            alert('Payment timed out.');
          })
          .catch(function () {
          });
      }, 20 * 60 * 1000); /* 20 minutes */

      request.show()
        .then(function (instrument) {
          window.clearTimeout(paymentTimeout);
        //   processResponse(instrument); // Handle response from browser.
        })
        .catch(function (err) {
          alert(err);
        });
    }
  }


  return (
    <div>

      <div className="bg-white py-4 px-4 text-[13.4px] flex items-center justify-between">
        <div className="flex items-center">
          {localStorage.np &&
            <img src={localStorage.np === "jio" ? Jio : localStorage.np === "airtel" ? Airtel : localStorage.np === "vi" ? Vi : localStorage.np === "bsnl" ? Bsnl : Jio} alt="" className='h-12 rounded-full' />}

          <div className="font-bold text-[14px] text-blue-900 ml-2">
            <div> Recharge for: {localStorage.number && localStorage.number}</div>

            <div className="text-slate-500 font-normal text-[12px] mt-[-2px]">


              {localStorage.np === "jio" ? "Jio" : localStorage.np === "airtel" ? "Airtel" : localStorage.np === "vi" ? "VI" : localStorage.np === "bsnl" ? "BSNL" : "Jio"} Prepaid</div>
          </div>
        </div>
        <Link to="/" className="text-blue-600">Change</Link>
      </div>
      <h1 className="text-[16px] font-bold text-slate-800 mx-4 border-b-4 border-blue-500 w-fit">Unlimited</h1>
      <div className="px-5 bg-white pt-1">
        <div className="bg-white rounded-xl p-4 my-4 shadow-lg">
          <div className="flex justify-between mt-3">
            <div className="text-[24px] font-bold">₹149</div>
            <div className="flex">
              <div className="text-right mr-6">
                <div className="text-slate-600 text-[15px]">VALIDITY</div>
                <div className="text-slate-600 text-[15px]">DATA</div>
                <div className="text-slate-600 text-[15px]">Voice</div>
              </div>
              <div className="">
                <div className="text-slate-800 text-[15px] font-bold">84 days</div>
                <div className="text-slate-800 text-[15px] font-bold">1.5 GB/day</div>
                <div className="text-slate-800 text-[15px] font-bold">Unlimited</div>
              </div>
            </div>
            <div className="bg-blue-500 rounded-full p-1 h-fit">
              <FaChevronRight size={18} className="text-white" />
            </div>
          </div>
          <div className="mt-5">
            <button onClick={() => openGpay(149.99)} className="bg-blue-500 py-2 w-full text-[15px] rounded-xl font-bold text-white">Recharge</button>
          </div>
        </div>
        <div className="bg-white rounded-xl p-4 my-4 shadow-lg">
          <div className="flex justify-between mt-3">
            <div className="text-[24px] font-bold">₹389</div>
            <div className="flex">
              <div className="text-right mr-6">
                <div className="text-slate-600 text-[15px]">VALIDITY</div>
                <div className="text-slate-600 text-[15px]">DATA</div>
                <div className="text-slate-600 text-[15px]">Voice</div>
              </div>
              <div className="">
                <div className="text-slate-800 text-[15px] font-bold">12 month</div>
                <div className="text-slate-800 text-[15px] font-bold">2.0 GB/day</div>
                <div className="text-slate-800 text-[15px] font-bold">Unlimited</div>
              </div>
            </div>
            <div className="bg-blue-500 rounded-full p-1 h-fit">
              <FaChevronRight size={18} className="text-white" />
            </div>
          </div>
          <div className="mt-5">
            <button onClick={() => openGpay(389.99)} className="bg-blue-500 py-2 w-full text-[15px] rounded-xl font-bold text-white">Recharge</button>
          </div>
        </div>
        <div className="bg-white rounded-xl p-4 my-4 shadow-lg">
          <div className="flex justify-between mt-3">
            <div className="text-[24px] font-bold">₹279</div>
            <div className="flex">
              <div className="text-right mr-6">
                <div className="text-slate-600 text-[15px]">VALIDITY</div>
                <div className="text-slate-600 text-[15px]">DATA</div>
                <div className="text-slate-600 text-[15px]">Voice</div>
              </div>
              <div className="">
                <div className="text-slate-800 text-[15px] font-bold">6 month</div>
                <div className="text-slate-800 text-[15px] font-bold">2.0 GB/day</div>
                <div className="text-slate-800 text-[15px] font-bold">Unlimited</div>
              </div>
            </div>
            <div className="bg-blue-500 rounded-full p-1 h-fit">
              <FaChevronRight size={18} className="text-white" />
            </div>
          </div>
          <div className="mt-5">
            <button onClick={() => openGpay(279.99)} className="bg-blue-500 py-2 w-full text-[15px] rounded-xl font-bold text-white">Recharge</button>
          </div>
        </div>
        <div className="bg-white rounded-xl p-4 my-4 shadow-lg">
          <div className="flex justify-between mt-3">
            <div className="text-[24px] font-bold">₹249</div>
            <div className="flex">
              <div className="text-right mr-6">
                <div className="text-slate-600 text-[15px]">VALIDITY</div>
                <div className="text-slate-600 text-[15px]">DATA</div>
                <div className="text-slate-600 text-[15px]">Voice</div>
              </div>
              <div className="">
                <div className="text-slate-800 text-[15px] font-bold">84 days</div>
                <div className="text-slate-800 text-[15px] font-bold">3.0 GB/day</div>
                <div className="text-slate-800 text-[15px] font-bold">Unlimited</div>
              </div>
            </div>
            <div className="bg-blue-500 rounded-full p-1 h-fit">
              <FaChevronRight size={18} className="text-white" />
            </div>
          </div>
          <div className="mt-5">
            <button onClick={() => openGpay(249.99)} className="bg-blue-500 py-2 w-full text-[15px] rounded-xl font-bold text-white">Recharge</button>
          </div>
        </div>
        <div className="bg-white rounded-xl p-4 my-4 shadow-lg">
          <div className="flex justify-between mt-3">
            <div className="flex">
              <div className="text-right mr-6">
                <div className="text-[24px] font-bold">₹199</div>
                <div className="text-slate-600 text-[15px]">VALIDITY</div>
                <div className="text-slate-600 text-[15px]">DATA</div>
                <div className="text-slate-600 text-[15px]">Voice</div>
              </div>
              <div className="">
                <div className="text-slate-800 text-[15px] font-bold">84 days</div>
                <div className="text-slate-800 text-[15px] font-bold">2.0 GB/day</div>
                <div className="text-slate-800 text-[15px] font-bold">Unlimited</div>
              </div>
            </div>
            <div className="bg-blue-500 rounded-full p-1 h-fit">
              <FaChevronRight size={18} className="text-white" />
            </div>
          </div>
          <div className="mt-5">
            <button onClick={() => openGpay(199.99)} className="bg-blue-500 py-2 w-full text-[15px] rounded-xl font-bold text-white">Recharge</button>
          </div>
        </div>

      </div>
      <div>

      </div>

    </div>
  )
}

export default Recharge