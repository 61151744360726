// import React, { useState } from 'react';
// import gpay from '../images/gpay.jpg';
// import flag from '../images/flag.jpeg';
// import jio from '../images/jio.png';
// import bsnl from '../images/bsnl.png';
// import airtel from '../images/airtel.png';
// import vi from '../images/vi.jpeg';
// import { FaMobile } from "react-icons/fa";
// import { BiSolidOffer } from "react-icons/bi";
// import { useNavigate } from 'react-router-dom';
// import jio_img from '../images/smalljio.png';
// import banner from '../images/b1.38f80d39d2eae08a1e4c.png';
// import footer from '../images/footer.png';



// const Home = () => {
//     const [selectedOperator, setSelectedOperator] = useState('Airtel');
//     const [mobileNumber, setMobileNumber] = useState('');
//     const [errorMessage, setErrorMessage] = useState('');
//     const navigate = useNavigate();

//     // Handler to set the selected operator
//     const operatorSelect = (operator) => {
//         setSelectedOperator(operator);
//     };

//     // Function to handle the change of mobile number input
//     const handleMobileNumberChange = (e) => {
//         setMobileNumber(e.target.value);
//         setErrorMessage(''); // Reset error message on input change
//     };

//     // Function to validate mobile number
//     const validateMobileNumber = () => {
//         // Simple regex for Indian mobile numbers (10 digits, starting with 7, 8, or 9)
//         const regex = /^[6-9]\d{9}$/;
//         if (!regex.test(mobileNumber)) {
//             setErrorMessage('Please enter a valid 10-digit mobile number.');
//             return false;
//         }
//         return true;
//     };

//     // Function to handle submit or pass data to another component
//     const handleSubmit = () => {
//         if (validateMobileNumber()) {
//             console.log('Selected Operator:', selectedOperator);
//             console.log('Mobile Number:', mobileNumber);
//             // Pass selectedOperator and mobileNumber to another component or perform an action
//             navigate('/plan', { state: { selectedOperator, mobileNumber } });

//         }
//     };

//     return (
//         <div>
//            <div class="py-4 px-6 bg-blue-700 flex items-center justify-between text-white">
//             <div class="flex items-center"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="text-white mr-3" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
//                     <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
//                 </svg>
//                 <a href="index-2.html">
//                     <img src={jio_img} alt="" class="w-8" />
//                 </a>
//             </div>
//             <div class="mx-2">
//                 <div class="searchBar">
//                     <input id="searchQueryInput" type="text" name="searchQueryInput" placeholder="Search" value="" />
//                     <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit"><svg class="h-[24px] w-[24px]" viewBox="0 0 24 24">
//                             <path fill="#fff" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"></path>
//                         </svg>
//                     </button>
//                 </div>
//             </div>
//             <div>
//                 <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="text-white" height="30" width="30" xmlns="http://www.w3.org/2000/svg">
//                     <path d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"></path>
//                 </svg>
//             </div>
//         </div>
//         <div class="bg-slate-100"><img src={banner} alt="" class="w-full" /></div>

//             <div className="px-4 mt-5">

//                 {/* <h1 className="text-[14px] font-semibold mt-4">Select Network Operator</h1> */}
//                 <div className="border border-slate-200 rounded-xl py-2 px-2">
// <div className="px-2 py-2 flex items-center justify-center mx-auto">
//     {/* <img src={gpay} alt="" className="h-12 mr-2 " /> */}
//     <FaMobile />
//     <div className="text-slate-800 font-semibold text-[18px] ml-2">Mobile Recharge</div>
// </div>
//                     <div className='flex justify-center'>
//                         <div className="flex flex-col items-center rounded-xl px-3 py-3">
//                             <label htmlFor="airtel" className={`flex items-center cursor-pointer `}>
//                                 <img src={airtel} alt="Airtel" className="h-12" />
//                             </label>
//                             <input
//                                 type="radio"
//                                 name="operator"
//                                 id="airtel"
//                                 value="Airtel"
//                                 checked={selectedOperator === 'Airtel'}
//                                 onChange={() => operatorSelect('Airtel')}
//                                 className="mt-5"
//                                 style={{ width: '17px', height: '17px' }}
//                             />
//                         </div>

//                         <div className="flex flex-col items-center rounded-xl px-3 py-3">
//                             <label htmlFor="jio" className={`flex items-center cursor-pointer`}>
//                                 <img src={jio} alt="Jio" className="h-12 rounded-full" />

//                             </label>
//                             <input
//                                 type="radio"
//                                 name="operator"
//                                 id="jio"
//                                 value="Jio"
//                                 checked={selectedOperator === 'Jio'}
//                                 onChange={() => operatorSelect('Jio')}
//                                 className="mt-5"
//                                 style={{ width: '17px', height: '17px' }}
//                             />
//                         </div>

//                         <div className="flex flex-col items-center rounded-xl px-3 py-3">
//                             <label htmlFor="vi" className={`flex items-center cursor-pointer `}>
//                                 <img src={vi} alt="Vi" className="h-12 rounded-[50%]" />
//                                 {/* <p className="ml-3">Vi Prepaid</p> */}
//                             </label>
//                             <input
//                                 type="radio"
//                                 name="operator"
//                                 id="vi"
//                                 value="Vi"
//                                 checked={selectedOperator === 'Vi'}
//                                 onChange={() => operatorSelect('Vi')}
//                                 className="mt-5"
//                                 style={{ width: '17px', height: '17px' }}
//                             />
//                         </div>

//                         <div className="flex flex-col items-center rounded-xl px-3 py-3">
//                             <label htmlFor="bsnl" className={`flex items-center cursor-pointer`}>
//                                 <img src={bsnl} alt="BSNL" className="h-12" />
//                                 {/* <p className="ml-3">BSNL Prepaid</p> */}
//                             </label>
//                             <input
//                                 type="radio"
//                                 name="operator"
//                                 id="bsnl"
//                                 value="BSNL"
//                                 checked={selectedOperator === 'BSNL'}
//                                 onChange={() => operatorSelect('BSNL')}
//                                 className="mt-5"
//                                 style={{ width: '17px', height: '17px' }}
//                             />
//                         </div>
//                     </div>

//                     <h1 className="text-[14px] font-semibold mt-4">Mobile Number</h1>
//                     <div className="flex items-center">

//                         <input
//                             type="number"
//                             className="bg-white mt-1 ml-2 border rounded-xl border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-4"
//                             placeholder="Enter 10 digit mobile number"
//                             required
//                             value={mobileNumber}
//                             onChange={handleMobileNumberChange}
//                         />
//                     </div>
//                     {errorMessage && <p className="text-red-500 text-sm mt-1">{errorMessage}</p>}
//                     <div class="bg-slate-100 flex items-center p-2 rounded-xl mt-5">
//                         <BiSolidOffer fill='#FBC02D' size={30} />
//                         <p class="text-[12px] font-semibold ml-2">Recharge with a ₹149 plan for a month full of digital delights.</p>
//                     </div>
//                 </div>

//                 <div className="bg-blue-500 w-full text-center rounded-md p-3 text-white font-bold text-[16px] mt-4" onClick={handleSubmit}>
//                     <div className="w-fit mx-auto flex items-center">
//                         View Plans
//                         <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="text-white mb-[-2px] ml-2" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
//                             <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
//                         </svg>
//                     </div>
//                 </div>

//             </div>
//             <img src={footer} alt="" class="w-full"></img>
//         </div>
//     );
// };

// export default Home;




import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaArrowRight } from "react-icons/fa";
import Ind from "../images/bsnl.png"
import Airtel from "../images/airtel.png"
import Jio from "../images/jio.png"
import Bsnl from "../images/bsnl.png"
import Vi from "../images/vi.jpeg"
import footer from '../images/footer.png';
import jio_img from '../images/smalljio.png';
import '../App.css';
import banner from '../images/b1.38f80d39d2eae08a1e4c.png';
import { FaMobile } from "react-icons/fa";
import { BiSolidOffer } from "react-icons/bi";

const Home = () => {
    const [operator, setOperator] = useState("Airtel")
    const navigate = useNavigate()
    const [number, setnumber] = useState()
    const [error, setError] = useState(false)
    const openOffer = () => {
        if (number?.length === 10) {
            localStorage.setItem("number", number)
            localStorage.setItem("np", operator)
            navigate("/plans")
        } else {
            setError(true)
        }
    }

    const operatorSelect = (operator) => {
        setOperator(operator)
    }
    return (<>

        <div class="py-4 px-6 bg-blue-700 flex items-center justify-between text-white ">
            <div class="flex items-center"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="text-white mr-3" height="20" width="20" xmlns="http:www.w3.org/2000/svg">
                <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
            </svg>
                <a href="index-2.html">
                    <img src={jio_img} alt="" class="w-8" />
                </a>
            </div>
            <div class="mx-2">
                <div class="searchBar">
                    <input id="searchQueryInput" type="text" name="searchQueryInput" placeholder="Search" value="" />
                    <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit"><svg class="h-[24px] w-[24px]" viewBox="0 0 24 24">
                        <path fill="#fff" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"></path>
                    </svg>
                    </button>
                </div>
            </div>
            <div>
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="text-white" height="30" width="30" xmlns="http:www.w3.org/2000/svg">
                    <path d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"></path>
                </svg>
            </div>
        </div>
        <div class="bg-slate-100"><img src={banner} alt="" class="w-full" /></div>
        <div className="px-2 py-2 flex items-center justify-center mx-auto mt-3">
            {/* <img src={gpay} alt="" className="h-12 mr-2 " /> */}
            <FaMobile />
            <div className="text-slate-800 font-semibold text-[18px] ml-2">Mobile Recharge</div>
        </div>


        <div className='flex justify-center'>
            <div className="flex flex-col items-center rounded-xl px-3 py-3 ">
                <label htmlFor="airtel" className="flex items-center cursor-pointer">
                    <img src={Airtel} alt="Airtel" className="h-12" />
                </label>
                <input
                    type="radio"
                    name="operator"
                    id="airtel"
                    value="Airtel"
                    checked={operator === 'Airtel'}
                    onChange={() => operatorSelect('Airtel')}
                    className="mt-2"
                    style={{ width: '17px', height: '17px' }}
                />
            </div>

            <div className="flex flex-col items-center rounded-xl px-3 py-3">
                <label htmlFor="jio" className="flex items-center cursor-pointer">
                    <img src={Jio} alt="Jio" className="h-12 rounded-full" />
                </label>
                <input
                    type="radio"
                    name="operator"
                    id="jio"
                    value="Jio"
                    checked={operator === 'Jio'}
                    onChange={() => operatorSelect('Jio')}
                    className="mt-2"
                    style={{ width: '17px', height: '17px' }}
                />
            </div>

            <div className="flex flex-col items-center rounded-xl px-3 py-3">
                <label htmlFor="vi" className="flex items-center cursor-pointer">
                    <img src={Vi} alt="Vi" className="h-12 rounded-[50%]" />
                </label>
                <input
                    type="radio"
                    name="operator"
                    id="vi"
                    value="Vi"
                    checked={operator === 'Vi'}
                    onChange={() => operatorSelect('Vi')}
                    className="mt-2"
                    style={{ width: '17px', height: '17px' }}
                />
            </div>

            <div className="flex flex-col items-center rounded-xl px-3 py-3">
                <label htmlFor="bsnl" className="flex items-center cursor-pointer">
                    <img src={Bsnl} alt="BSNL" className="h-12" />
                </label>
                <input
                    type="radio"
                    name="operator"
                    id="bsnl"
                    value="BSNL"
                    checked={operator === 'BSNL'}
                    onChange={() => operatorSelect('BSNL')}
                    className="mt-2"
                    style={{ width: '17px', height: '17px' }}
                />
            </div>
        </div>
<div className='px-6'>
        <h1 className="text-[14px] font-semibold mt-4 ml-3 py-2">Enter Mobile Number</h1>
        <div className="flex items-center">

            <input
                type="number"
                onChange={(e) => setnumber(e.target.value)}
                value={number || ""}
                className="bg-white mt-1 ml-2 border rounded-xl border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-4" 
                placeholder='Enter 10 digit mobile number' required />
        </div>
        
        {error && <small className="text-red-500 my-1">Please enter valid mobile number!!</small>}
        <div class="bg-slate-100 flex items-center p-2 rounded-xl mt-5">
                        <BiSolidOffer fill='#FBC02D' size={30} />
                    <p class="text-[12px] font-semibold ml-2">Recharge with a ₹149 plan for a month full of digital delights.</p>
                   </div>


        <div className="bg-blue-500 w-full text-center rounded-md p-3 text-white font-bold text-[16px] mt-4" onClick={openOffer}>
            <div className="w-fit mx-auto flex items-center">View Plans<FaArrowRight className="text-white mb-[-2px] ml-2" size={20} /></div>
        </div>

        </div>
       
        <img src={footer} alt="" class="w-full"></img>
    </>)
}

export default Home