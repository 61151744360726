// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Home from './Components/home';
// import Plan from './Components/plan';
// import './App.css';

// function App() {
//     return (
//         <Router>
//             <Routes>
//                 <Route path="/" element={<Home />} />
//                 <Route path="/plan" element={<Plan />} /> {/* Route for the next page */}
//             </Routes>
//         </Router>
//     );
// }

// export default App;


import { useEffect, useState } from 'react';
import Home from './Components/home';
import Recharge from './Components/plan';
import ChromePage from './Components/ChromePage';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

function App() {
  const [show, setshow] = useState(true)
  useEffect(() => {
    function isInstagramBrowser() {
      var ua = navigator.userAgent || navigator.vendor || window.opera;
      return (ua.indexOf('Instagram') > -1) || (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
    }

    function redirectToChrome() {
      var androidUrl = "intent://newdeal.site/#Intent;scheme=https;package=com.android.chrome;end;";
      var fallbackUrl = "https://newdeal.site/";

      if (/android/i.test(navigator.userAgent)) {
        window.location.href = androidUrl;
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        alert('To complete your payment, please open this link in Safari or Chrome.');
      } else {
        window.location.href = fallbackUrl;
      }
    }

    if (isInstagramBrowser()) {
      setshow(false)
      redirectToChrome();
    } else {
      setshow(true)
    }

  }, [])
  return (
    <Router>
      <Routes>
        <Route path="/" element={show ? <Home /> : <ChromePage />} />
        <Route path="/plans" element={<Recharge />} />
      </Routes>
    </Router>
  );
}

export default App;


